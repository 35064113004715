import instance from "../utils/post"

export const userLogin = (data) => {
    return instance({
        url: "login",
        method: "post",
        data: data
    })
}

export const get = (url,params) =>{
    return instance({
        url: url,
        method: "get",
        params:params
    })
}

export const post = (url,data) =>{
    return instance({
        url: url,
        method: "post",
        data: data
    })
}
