import axios from "axios";
import router from "../router";
import {TOKENNAME, USERINFO} from "@/utils/util";
import {ElMessage} from "element-plus";


// eslint-disable-line no-unused-vars
const instance = axios.create({
    // baseURL: "http://192.168.0.107:8001/syno/",
    // baseURL: "http://192.168.0.181:8001/syno/",
    // baseURL: "http://192.168.0.119:8001/syno/",
    // baseURL: "http://192.168.0.119:8000/",
    baseURL: "https://reset.api.tkplusemi.com/",
    timeout: 30000
})


instance.interceptors.request.use((config) => {
        // const token = localStorage.getItem(TOKENNAME);
        // const token = "localStorage.getItem(TOKENNAME)";
        // const userInfo = JSON.parse(localStorage.getItem(USERINFO));
        // console.log(userInfo)

        config.headers = {

            "Content-Type": "application/json",
            "x-token": localStorage.getItem("x-token")
            // "userInfo":JSON.stringify(userInfo)
        }

        return config
    },
    error => {
        console.log(error)
    });




instance.interceptors.response.use(response => {
        if (response.status === 200) {
            const {data} = response
            if (data.code === 0) {
                return data
            } else if (data.code === 7 && data.reload) {
                router.push("/qrlogin").then(() => {
                    ElMessage.error(data.msg)
                })
                 // Promise.reject(data.msg)
            } else {
                // router.push("/qrlogin").then(() => {
                //     ElMessage.error(data.msg)
                // })
                // // router.push({name:'syno_project'}).then(() => alert(data.msg))
                ElMessage.error(data.msg)

            }
        } else {
            alert("网络错误")
        }
    },
    error => {
        return Promise.reject(error)
    })


export default instance
