<template>
  <h1>
    修改密码
  </h1>
  <div style="">
    <el-select
        v-model="category"
        clearable
        multiple
        placeholder="选择系统"
        size="large"
        style="width: 240px ;margin: 2vh"
    >
      <el-option
          v-for="item in options"
          :key="item.value"
          :label="item.label"
          :value="item.value"
      />
    </el-select>
    <el-input
        v-model="passwd"
        style="width: 240px ;margin: 2vh"
        type="password"
        placeholder="请输入你的密码"
        show-password
    />
    <el-button type="primary" style="width: 200px ;margin: 1vh" @click="postHandle()">确认修改</el-button>

  </div>
</template>


<script>
export default {
  name: 'changeA',
}
</script>

<script setup>
import {onBeforeMount, onMounted, ref} from 'vue'
import {ElMessage, ElMessageBox} from "element-plus";
import {post} from '@/api/user'

// onBeforeMount(() => {
//   // 1、检查token是否过期，未过期直接跳转首页
//   post('check_token', {
//   }).then(res => {
//     console.log(res)
//   })
// });


const category = ref('')
const username = ref('')
const passwd = ref('')
const url = ref('')
const options =ref([])
// const options = [
  // {
  //   value: 'BPM',
  //   label: 'BPM',
  // },
  // {
  //   value: '邮箱',
  //   label: '邮箱',
  // },
  // {
  //   value: '公司内部系统',
  //   label: '公司内部系统',
  // },
  // {
  //   value: 'ETX、内网研发文档、外网共享盘（\\\\192.168.0.42）',
  //   label: 'ETX、内网研发文档、外网共享盘（\\\\192.168.0.42）',
  // },
  // {
  //   value: 'linux服务器',
  //   label: 'linux服务器',
  // },
// ]

//  检查密码强度
const check_passwd = () => {
  // console.log(passwd.value)
  let pwdRegex = /^(?=.*[0-9])(?=.*[A-Z])(?=.*[a-z])(?=.*[!@#$%^&*?]).{8,16}$/;
  if (!pwdRegex.test(passwd.value)) {
    ElMessageBox.alert("您的密码复杂度太低（密码中必须包含大小写字母、数字、特殊字符），请及时修改密码！", '提示', {
      confirmButtonText: '确定',
    })
    return false
  } else {
    return true
  }
}


const urlHandle = () => {
  post(url.value, {
    'user': username.value,
    'Passwd': passwd.value,
  }).then(res => {
    console.log(res)
    ElMessageBox.alert(res.msg, '提示', {
      confirmButtonText: '确定',
    })
  })
}
const postHandle = () => {
  let selectItem = false
  if (check_passwd()) {
    if (category.value.includes("BPM")) {
      url.value = "bpm"
      username.value = localStorage.getItem("employee_no")
      console.log("包含bpm")
      selectItem = true
      urlHandle()
    }
    if (category.value.includes("邮箱")) {
      url.value = "email"
      username.value = localStorage.getItem("email")
      console.log("包含email")
      selectItem = true
      urlHandle()
    }
    if (category.value.includes("ETX、内网研发文档、外网共享盘（\\\\192.168.0.42）")) {
      url.value = "ad"
      username.value = localStorage.getItem("username")
      console.log("包含etx")
      selectItem = true
      urlHandle()
    }
    // if (category.value.includes("linux服务器")) {
    //   url.value = "linux"
    //   username.value = localStorage.getItem("linux_name")
    //   console.log("包含linux")
    //   selectItem = true
    // }

    if (selectItem === false) {
      ElMessageBox.alert("请选择要修改密码的系统")
    }

  }


}
onMounted(()=>{
  if (localStorage.getItem("employee_no")){

    options.value.push({
      value: 'BPM',
      label: 'BPM',
    })
  }
  if (localStorage.getItem("email")){

    options.value.push({
      value: '邮箱',
      label: '邮箱',
    })
  }
  if (localStorage.getItem("username")){

    options.value.push({
      value: 'ETX、内网研发文档、外网共享盘（\\\\192.168.0.42）',
      label: 'ETX、内网研发文档、外网共享盘（\\\\192.168.0.42）',
    })
  }

})

</script>


<style scoped>
.el-row {
  margin-bottom: 20px;
}

.el-row:last-child {
  margin-bottom: 0;
}

.el-col {
  border-radius: 4px;
}

.grid-content {
  border-radius: 4px;
  min-height: 36px;
  background: #42b983;
}
</style>

