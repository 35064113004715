import change_A from "@/components/change_passwd.vue";
import HelloWorld from "@/components/HelloWorld.vue";
import Main from "@/components/main.vue"
import {createRouter, createWebHistory} from "vue-router";
import login from "@/views/login.vue";
import {ElMessage} from "element-plus";
import {onMounted, ref} from "vue";
import {post} from "@/api/user";


const routes = [
    {
        path: '/', name: 'base', meta: {
            isLogin: true,
        }, component: () => import("../views/HomeView.vue"),
        children: [
            {
                path: '/change_passwd',
                name: 'passwd',
                meta:{
                    isLogin: true,
                },
                component: () => import("@/components/change_passwd.vue")
            },
            {
                path: '/query',
                name: 'query_account',
                meta:{
                    isLogin: true,
                },
                component: () => import("@/components/query_userName.vue")
            }
        ]

    },
    {
        path: '/qrLogin', name: 'login', meta: {
            isLogin: false,
        }, component: login
    },
    // {
    //     path: '/main', name: 'Main', component: Main,
    //     children: [
    //         //     {
    //         //     path:'changeB',
    //         //     name:'change_B',
    //         //     component:change_B
    //         // },
    //         //     {
    //         //     path:'h2',
    //         //     name:'H2',
    //         //     component:H2
    //         // }
    //     ]
    // }
]
const router = createRouter({
    // 4. 内部提供了 history 模式的实现。为了简单起见，我们在这里使用 hash 模式。
    history: createWebHistory(),
    routes, // `routes: routes` 的缩写
})

/**
 * 全局前置路由守卫，每一次路由跳转前都进入这个 beforeEach 函数
 */
router.beforeEach((to, from, next) => {
    // 判断是否登录
    if(to.meta.isLogin) {
        const token = localStorage.getItem('x-token');
        // 1、检查token是否存在，不存在直接跳转首页
        if(token) {
            post('check_token', ).then(res => {
                // console.log(res)
                if (res){
                    if(res.status.toString() === "200") {
                        next();
                    }
                    else {
                        next('/qrlogin');
                    }
                }
            })
        } else {
            next('/qrlogin');
        }
    } else {
        next();
    }
});


export default router
